




























import { Vue, Component } from 'vue-property-decorator'
import { apiMapGet, apiMapSet } from '@/api/shop'
@Component
export default class MapSetting extends Vue {
	/** S Data **/
	// 表单数据
	form = {
		tencent_map_key: ''
	}
	// 表单验证
	rules = {
		tencent_map_key: [
			{
				required: true,
				message: '请输入腾讯地图的配置Key'
			}
		]
	}

	/** E Data **/

	/** S Methods **/
	// 初始化表单数据
	initFormData() {
		apiMapGet()
			.then(res => {
				// 表单同步于接口响应数据
				this.form = res
			})
			.catch(() => {
				this.$message.error('数据加载失败，请刷新重载')
			})
	}

	// 提交表单
	onSubmitFrom(formName : string) {
		const refs = this.$refs[formName] as HTMLFormElement

		refs.validate((valid : boolean) => {
			if (!valid) {
				return
			}
			const loading = this.$loading({ text: '保存中' })

			apiMapSet({
				...this.form
			})
				.then(() => {
					this.$message.success('保存成功')
				})
				.catch(() => {
					this.$message.error('保存失败')
				})
				.finally(() => {
					loading.close()
				})
		})
	}
	/** E Methods **/

	/** S Life Cycle **/
	created() {
		this.initFormData()
	}
	/** E Life Cycle **/
}
